.btn {
    border: 0;
    color: $omnia-blue;
    text-align: center;
    padding: 2rem 4.5rem;
    display: inline-block;
    border-radius: 3.5rem;
    background-color: #EEF3F9;
    transition: color $omnia-transition-fast, background-color $omnia-transition-fast;

    svg {
        height: 1.6rem;
        display: block;
        fill: $omnia-blue;
        stroke: $omnia-blue;
        margin-right: 1rem;
        transition: fill $omnia-transition-fast, stroke $omnia-transition-fast;
    }
    .flex {
        white-space: nowrap;
        align-items: center;
    }
    &--slim {
        padding: 2.5rem;
        border-radius: 3.5rem;

        svg {
            margin-right: 0;
        }
    }
    &--small {
        padding: 1.5rem 2rem;
    }
    &--secundary {
        background-color: $omnia-white;
    }
    &:hover {
        color: $omnia-white !important;
        background-color: $omnia-blue !important;

        svg {
            fill: $omnia-white;
            stroke: $omnia-white;
        }
    }

    &.reverse-icon {
        svg {
            transform: rotate(180deg);
        }
    }

    &.bordered {
        border: 1px solid $omnia-blue;
    }
}
