.product-item {
    .product-header {
        margin-bottom: 2rem;
        background-color: white;
        border-radius: 20px;
        padding: 2rem;

        h4 {
            margin-bottom: 2rem;
            font-size: 22px;
        }

        input {
            background-color: #F5F5F5;
        }

        .switch input + label::before {
            background-color: #F5F5F5;
        }
    }

    .product-image-container {
        width: 45%;

        img {
            border-radius: 20px;
            width: 100%;
        }
    }

    .specifications__item {
        display: block;

        &.specifications-text {
            text-align: justify;
        }
    }
}


