.order-options-container {
    &.with-channels {
        display: flex;
        flex-direction: row;


        @media screen and (max-width: 959px) {
            flex-direction: column-reverse !important;
        }

        .order-options {
            width: 80%;

            @media screen and (max-width: 959px) {
                width: 100%;
            }
        }

        .order-options-channels {
            width: 20%;
            display: flex;
            align-items: center;
            flex-direction: column;

            @media screen and (max-width: 959px) {
                justify-content: center;
                width: 100%;
                flex-direction: row;
            }

            .channel-badge {
                border: 1px solid #ccc;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                width: 100px;
                height: 100px;
                background-color: white;
                box-sizing: content-box;
                filter: drop-shadow(0px 4px 6px #aaa);
                margin-bottom: 20px;
                overflow: hidden;

                @media screen and (max-width: 959px) {
                    margin-right: 10px;
                    margin-left: 10px;
                }

                &:hover {
                    filter: drop-shadow(0px 1px 6px #aaa);
                }

                img {
                    width: 90%;
                }
            }
        }
    }
}

.text-read-more {
    a {
        color: $omnia-accent;
    }

    input {
        display: inline-block;
        margin-right: 10px;
    }
}
