.m-hide {
    display: none;

    @media screen and (min-width: 768px) {
        display: inline-block;
    }
}
.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 33px;
    max-width: 700px;

    &--large {
        max-width: none;
        padding: 0 1.5rem;
    }
    @media screen and (min-width: 768px) and (max-width: 959px) {
        max-width: 708px;
    }

    @media screen and (min-width: 960px) and (max-width: 1239px) {
        max-width: 940px;
    }

    @media screen and (min-width: 1240px) {
        max-width: 1210px;
    }
}

.flex {
    display: flex !important;
    align-items: center;

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.content-center {
        justify-content: center;
    }

    &.between {
        justify-content: space-between;
    }

    &.items-center {
        align-items: center;
    }

    &.items-end {
        align-items: flex-end;
    }

    &.items-start {
        align-items: flex-start;
    }

    &.row {
        flex-direction: row;
    }

    &.wrap {
        flex-wrap: wrap;
    }
}

.flex-start {
    align-items: flex-start !important;
}

.col {
    flex-direction: column !important;
}

.w-100 {
    width: 100% !important;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 20px;
}

.mb-3 {
    margin-bottom: 30px;
}

.mb-4 {
    margin-bottom: 40px;
}

.mt-2 {
    margin-top: 20px;
}

.transparent {
    background-color: transparent !important;
}

.align-self-end {
    align-self: flex-end;
}

.icon-colored {
    svg {
        height: 1.6rem;
        display: block;
        fill: $omnia-blue;
        stroke: $omnia-blue;
        margin-right: 1rem;
        transition: fill $omnia-transition-fast, stroke $omnia-transition-fast;
    }
}

.d-none {
    display: none !important;
}

.warning {
    color: $warning-red;
}

.text-primary {
    color: $omnia-red;
}

.text-gray {
    color: $omnia-gray;
}

.accent {
    color: $omnia-accent !important;
}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.center {
    justify-content: center;
    align-items: center;
}

.border {
    border: 1px solid;

    &.gray {
        border-color: #ccc;
    }
}

.dropshadow {
    filter: drop-shadow(0px 12px 14px #666);
}

.italic {
    font-style: italic;
}

.show-newlines {
    white-space: pre-wrap;
}

.text-read-more {
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.opened {
        max-height: none;
        height: auto;

    }
}

.read-more-anchor {
    font-size: 15px;
    margin-top: 25px;
}

.text-align-right {
    text-align: right;
}

.block {
    display: block;
}
