.packages {
    width: 100%;
    justify-content: center;

    .package {
        @media screen and (min-width: 960px) {
            width: 25%;

            &:nth-child(1) {
                .package__price-wrapper--colored {
                    background-image: linear-gradient(to right, $omnia-red, #DF0C30);
                }
            }
            &:nth-child(2) {
                .package__price-wrapper--colored {
                    background-image: linear-gradient(to right, #DF0C30, #BD113A);
                }
            }
            &:nth-child(3) {
                .package__price-wrapper--colored {
                    background-image: linear-gradient(to right, #BD113A, #86244A);
                }
            }
            &:nth-child(4) {
                .package__price-wrapper--colored {
                    background-image: linear-gradient(to right, #86244A, #443C5F);
                }
            }
            &:not(:last-child) {
                margin-right: 2px;
            }
        }
        @media screen and (min-width: 1400px) {
            max-width: 22rem;
        }
    }
    @media screen and (min-width: 960px) {
        flex: 1;
        display: flex;
        justify-content: center;
    }
    @media screen and (min-width: 1400px) {
        justify-content: flex-start;
    }
}

.package {
    position: relative;
    margin-bottom: 8rem;

    &__wrapper {
        display: flex;
        justify-content: center;
    }

    &__badge {
        bottom: 100%;
        right: 2.5rem;
        display: flex;
        width: 7.5rem;
        height: 7.5rem;
        position: absolute;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        transform: translateY(50%);
        background-color: $omnia-red;

        svg {
            width: 6rem;
            display: block;
        }
        @media screen and (min-width: 960px) {
            right: 0;
            transform: translate(50%, 50%);
        }
    }
    &--most-chosen {
        .package__info {
            margin-top: 10rem;
            position: relative;
            border-radius: 0 0 8px 8px;
            padding-top: calc(2.5rem + 4px);

            &::before {
                font-size: 1.5rem;
                font-weight: bold;
                color: $omnia-red;
                position: absolute;
                content: attr(data-ribbon);
                text-transform: uppercase;
                bottom: calc(100% + 1.5rem);
            }
            @media screen and (min-width: 960px) {
                margin-top: 0;
                padding-top: 2.5rem;
            }

            &::after {
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                content: "";
                height: 4px;
                position: absolute;
                background-color: $omnia-red;
                border-radius: 8px 8px 0 0;
            }
        }
    }
    &__card {
        display: flex;
        padding: 2.5rem;
        align-items: center;
        border-radius: 8px;
        flex-direction: column;
    }
    &__title {
        font-weight: 600;
        font-size: 1.8rem;
        color: $omnia-purple;
        margin-bottom: 1.75rem;
    }
    &__visual {
        height: 15rem;
        display: block;
    }
    &__detail {
        width: 100%;
        display: flex;
        line-height: 1.6;
        text-align: center;
        align-items: center;
        flex-direction: column;

        svg {
            width: 20px;
            display: block;
            height: 25.59px;
        }
        &s {
            display: none;
            margin-top: .75rem;

            &--active {
                display: block;
            }
            @media screen and (min-width: 960px) {
                display: block;
                margin-top: 2.5rem;
            }
        }
        &-label {
            font-weight: 600;
            margin-bottom: 2rem;
            color: $omnia-purple;

            @media screen and (min-width: 960px) {
                margin-bottom: 1.5rem;
            }

            @media screen and (min-width: 1400px) {
                display: none;
            }
        }
        &:first-child {
            margin-top: -1rem;
        }
        &:last-child {
            margin-bottom: -1rem;
        }
        &:not(:last-child) {
            &::after {
                width: 100%;
                content: "";
                height: 1px;
                margin: 2rem 0;
                display: block;
                background-color: #EDEDED;

                @media screen and (min-width: 960px) {
                    margin: 1.5rem 0;
                }
            }
        }
    }
    &__heads {
        display: none;
        margin-right: .5rem;

        .card:first-child {
            opacity: 0;
        }
        .package__detail {
            align-items: flex-start;

            &-label {
                display: block;
                margin-bottom: 0;
                white-space: nowrap;
            }
        }
        @media screen and (min-width: 1400px) {
            display: block;
        }
    }
    &__pricing {
        display: flex;
        margin-top: .75rem;
        white-space: nowrap;
        flex-direction: column;

        .card {
            padding: 2.5rem;

            &:first-child {
                border-radius: 8px 8px 0 0;
            }
            &:nth-child(2) {
                border-radius: 0 0 8px 8px;

                @media screen and (min-width: 960px) {
                    padding: 1.5rem;
                    flex-direction: row;
                    justify-content: center;

                    * {
                        font-size: 1.6rem;
                    }
                }
            }
            @media screen and (min-width: 375px) {
                width: 50%;

                &:first-child {
                    border-radius: 8px 0 0 8px;
                }
                &:nth-child(2) {
                    border-radius: 0 8px 8px 0;
                }
            }
            @media screen and (min-width: 960px) {
                width: 100%;

                &:first-child {
                    border-radius: 8px 8px 0 0;
                }
                &:nth-child(2) {
                    border-radius: 0 0 8px 8px;
                }
            }
        }
        @media screen and (min-width: 375px) {
            flex-direction: row;
        }

        @media screen and (min-width: 960px) {
            margin-top: 2.5rem;
            flex-direction: column;
        }
    }
    &__price {
        display: flex;
        margin-bottom: 1rem;
        color: $omnia-purple;
        align-items: baseline;

        &-wrapper {
            text-align: center;

            &.package-price-container {
                min-height: 140px;
            }

            .price-discount {
                font-size: 1.4rem;
            }

            .price-after {
                font-size: 1.4rem;
            }

            p {
                font-size: 1.6rem;
            }
            &--colored {
                color: $omnia-white;
                background-image: linear-gradient(to right, $omnia-red, #443C5F);

                * {
                    color: inherit !important;
                }
            }
            @media screen and (min-width: 960px) {
                &:nth-child(2) {
                    .package__price-label--small {
                        padding-top: 0;
                    }
                }
            }
        }
        &-label {
            display: flex;
            font-weight: 700;
            font-size: 3.4rem;
            color: $omnia-red;
            margin-right: .2rem;
            align-items: flex-start;

            &--small {
                font-size: 1.6rem;
                padding-top: 1rem;
            }
        }
        @media screen and (min-width: 960px) {
            margin-bottom: 0;
            margin-right: .25rem;
        }
    }
    &__nav {
        display: flex;
        margin-top: 1.5rem;
        align-items: center;
        padding-right: 2rem;
        justify-content: space-around;

        @media screen and (min-width: 960px) {
            padding-right: 0;
            margin-top: 1.75rem;
        }
    }
    &__link,
    &__expand {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    &__link {
        img {
            margin-right: 1rem;
        }
    }
    &__expand {
        color: #575756;

        img {
            margin-left: 1rem;
            transition: rotate $omnia-transition-fast;
        }
        &--active img {
            rotate: 180deg;
        }
        @media screen and (min-width: 960px) {
            display: none;
        }
    }
    &:not(:last-child) {
        margin-bottom: 7rem;
    }
}

.card-background {
    margin: 0 50px;
    padding: 0 10px 40px;
    background-color: #ebf0f6;
    border-radius: 10% 0 0 0;

    .column_container {
        max-width: 960px;
        flex-direction: column;

        @media screen and (min-width: 960px) {
            flex-direction: row;
        }
    }

    .card {
        margin-top: 3rem;
        margin-left: -30px;

        @media screen and (min-width: 960px) {
            margin-left: 0;
        }

        img {
            margin-right: 2rem;
            margin-bottom: 10px;
        }

        p {
            margin-top: 2rem;
        }
    }

    .package-col-left {
        .card {
            &:first-child {
                margin-top: 8rem;
            }
        }
    }

    .package-col {
        width: 90%;

        h3 {
            margin-bottom: 0;
        }

        &-left {
            margin-left: -40px;
        }

        &-right {
            margin-left: -40px;

            @media screen and (min-width: 960px) {
                margin-left: 40px;
            }
        }
    }
}

.package-buttons {
    svg {
        margin-right: 1em !important;
    }
}
