.specifications {
    margin-bottom: 8rem;

    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column-reverse;

        .card {
            max-width: 100%;

            &:first-child {
                flex: 1;

                @media screen and (min-width: 960px) {
                    margin-right: 2rem;
                }
            }
            &:last-child {
                margin-bottom: 2rem;

                @media screen and (min-width: 960px) {
                    width: 42rem;
                    margin-bottom: 0;
                }
            }
        }

        &.small-column-first {
            .card {
                max-width: 100%;

                &:first-child {
                    width: 42rem;
                    flex: none;

                    @media screen and (min-width: 960px) {
                        margin-left: 2rem;
                    }
                }

                &:last-child {
                    flex: 1;
                    margin-bottom: 2rem;
                    width: auto;

                    @media screen and (min-width: 960px) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media screen and (min-width: 960px) {
            flex-direction: row;
        }
    }
    &__item {
        display: flex;
        padding-bottom: 2rem;
        flex-direction: column;

        &-wrapper {
            &:not(:first-child) {
                padding-top: 2rem;
            }
            &:not(:last-child) {
                &::after {
                    width: 100%;
                    content: "";
                    margin: 3rem 0;
                    height: .1rem;
                    display: block;
                    background-color: #DFDFDF;
                }
            }
            &:last-child {
                .specifications__item {
                    padding-bottom: 3.5rem;
                }
            }
        }
        @media screen and (min-width: 768px) {
            flex-direction: row;
            align-items: center;
        }
    }
    &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__info {
        margin-bottom: 2.5rem;

        & > * {
            display: block;
        }
        @media screen and (min-width: 768px) {
            margin-bottom: 0;
            margin-right: 5rem;
        }

        @media screen and (min-width: 960px) {
            margin-right: 6rem;
        }
    }
    &__image {
        display: block;
        margin-bottom: 1rem;
        align-self: flex-start;

        img {
            display: block;
        }
        @media screen and (min-width: 768px) {
            margin-bottom: 0;
            margin-right: 1.5rem;
        }

        &--shadow {
            margin-left: -1.5rem;

            img {
                width: auto !important;
                height: 8rem;
            }
        }
        &--device {
            margin-bottom: 2.5rem;

            img {
                width: 6rem;
                height: 6rem;
                object-fit: contain;
            }
            @media screen and (min-width: 768px) {
                margin-bottom: 0;
                margin-right: 3.5rem;
            }
        }
        &--icon {
            margin-bottom: 2.5rem;

            img {
                width: 4.5rem;
                height: 4.5rem;
            }
            @media screen and (min-width: 768px) {
                margin-bottom: 0;
                margin-right: 3.5rem;
            }
        }
        &--phone-icon {
            img {
                width: initial;
                height: initial;
            }
        }
    }
    &__product-image {
        margin-right: 3.5rem;

        img {
            width: 57px !important;
            height: auto;
        }
    }
    &__title {
        font-weight: 600;
        margin-bottom: .5rem;
        color: $omnia-purple;
    }
    &__description {
        max-width: 36rem;
    }
    &__interaction {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media screen and (min-width: 768px) {
            align-items: flex-end;
        }
    }
    &__label {
        margin-bottom: 1rem;
        font-size: 1.3rem;
        white-space: nowrap;
        color: $omnia-white;
        padding: .5rem 2.5rem;
        border-radius: 1.5rem;
        background-color: $omnia-blue;
    }
    &__number-input {
        width: 7rem;
        border: none;
        outline: none;
        font-size: 1.6rem;
        color: $omnia-gray;
        border-radius: .25rem;
        padding: .5rem 1rem .5rem 1.5rem;
        background-color: $omnia-white;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            opacity: 1;
        }
    }
    &__divider {
        width: 100%;
        height: .1rem;
        margin: 3rem 0;
        background-color: #DFDFDF;
    }
    &__price {
        font-weight: 600;
        color: $omnia-red;
        white-space: nowrap;
        line-height: 1.6;

        &-heading {
            font-weight: 600;
            color: $omnia-purple;
        }
        &-description {
            margin-top: .5rem;
            color: $omnia-gray;
        }
        &-item {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            &s-total {
                .specifications__price-item:not(:last-child) {
                    margin-bottom: 1rem;

                    @media screen and (min-width: 768px) {
                        margin-bottom: 1.5rem;
                    }
                }
            }
            &:not(:last-child) {
                margin-bottom: 3rem;
            }
        }
        &-info {
            line-height: 1.6;

            & > * {
                display: block;
            }
        }
        &-btn {
            margin-top: 5rem;
        }
    }
    @media screen and (min-width: 768px) {
        margin-top: 5rem;
    }

    &__buttons {
        display: flex;
        margin-top: 5rem;
        justify-content: space-between;

        .btn:first-child svg {
            rotate: 180deg;
        }
        &-span {
            display: none;
        }
        @media screen and (min-width: 768px) {
            //justify-content: flex-end;

            &-span {
                display: block;
            }
            .btn {
                //padding: 2rem 4.5rem;
                margin-left: 13px;

                svg {
                    margin-right: 1rem;
                }
                &:first-child {
                    //background-color: $omnia-white;

                    svg {
                        //display: none;
                    }
                }
                &:last-child {
                    margin-left: 13px;
                }

                &.btn-backbutton {
                    svg {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    &__radio-spread {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form__label {
            cursor: pointer;
            font-weight: 600;
            color: $omnia-purple;
        }
        .form__radio-wrapper {
            margin-bottom: 0;
        }
        .form__radio {
            background-color: $omnia-white;
        }
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
        &:last-child {
            margin-bottom: 4rem;
        }
    }
    &__advantage {
        position: relative;
        color: $omnia-gray;

        &s {
            list-style: none;
        }
        &::before {
            top: 5px;
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            background-size: 16px;
            right: calc(100% + .75rem);
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('/public/img/themes/omnia/icons/checkmark.svg');
        }
        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }
}

.pcc-specification {
    background-color: white;
    margin-top: 0;

    .container {
        padding-top: 80px;
    }

    .pcc-form-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 1216px) {
            flex-direction: column;
        }

        .form__group {
            flex-grow: 1;
        }

        .specifications__buttons {
            @media screen and (min-width: 1216px) {
                margin-top: 0;
            }
        }

        .form__group {
            margin-bottom: 0;
        }
    }
}
