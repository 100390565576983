@font-face {
    font-family: "Ubuntu";
    font-weight: 500;
    src: url('/assets/fonts/ubuntu/Ubuntu-R.ttf') format('truetype');
}

@font-face {
    font-family: "Ubuntu";
    font-weight: 600;
    src: url('/assets/fonts/ubuntu/Ubuntu-M.ttf') format('truetype');
}

@font-face {
    font-family: "Ubuntu";
    font-weight: bold;
    src: url('/assets/fonts/ubuntu/Ubuntu-B.ttf') format('truetype');
}

h1 {
    font-weight: 600;
    font-size: 4.8rem;
    color: $omnia-purple;
    margin-bottom: 4.5rem;

    @media screen and (min-width: 960px) {
        font-size: 8rem;
        margin-bottom: 4rem;
    }
}
.h3 {
    font-weight: 500;
    display: block;
    font-size: 2rem;
    margin-bottom: 4rem;
    color: $omnia-purple;
}
input {
    font-family: "Ubuntu", sans-serif;
}
p,
span,
label {
    font-size: 1.5rem;

    @media screen and (min-width: 960px) {
        font-size: 1.6rem;
    }
}
p,
ul,
li {
    line-height: 1.6;
    color: $omnia-gray;
}
a {
    cursor: pointer;
    text-decoration: none;
}
ul {
    padding-left: 2.5rem;
}
