.accordion {
    margin: 0 -2rem;
    border-radius: 1rem;
    padding: 1.5rem 2rem;
    background-color: $omnia-light-gray;

    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    &__title {
        flex: 1;
        line-height: 1.5;
        color: $omnia-purple;

        @media screen and (min-width: 768px) {
            font-size: 2rem;
        }
    }
    &__button {
        padding: 1rem;
        border-radius: .5rem;
        color: $omnia-purple;
        background-color: $omnia-white;

        @media screen and (min-width: 768px) {
            font-size: 1.8rem;
            padding: 1rem 1.25rem;
        }
    }
    &__body {
        max-height: 0rem;
        overflow: hidden;
        transition: max-height $omnia-transition-fast, margin-top $omnia-transition-fast;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;

        .accordion:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
    &--active {
        .accordion__button {
            color: $omnia-red;
        }
        .accordion__body {
            max-height: none;
            margin-top: 3.5rem;

            @media screen and (min-width: 768px) {
                margin-top: 6rem;
            }
        }
    }
    @media screen and (min-width: 768px) {
        margin: 0;
        padding: 1.5rem 2.5rem;
    }
}

form {
    .accordion__wrapper:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.ordertypes-container {
    margin-top: 20px;

    .accordion {
        margin: 0;

        .icon-colored {
            padding-left: 5px;
        }
    }
}
