.progressbar {
  position: relative;

  &__wrapper {
    padding: 2rem 0;
    margin-top: 5rem;

    @media screen and (min-width: 768px) {
      padding: 5rem 0;
    }

    .bullet-header {
      display: flex;
      margin-top: 4rem;

      &.card {
        padding: 1rem 0;
      }

      @media screen and (min-width: 768px) {
        display: none;
      }

      .h3 {
        margin: 0;
      }
    }
  }
  .bar {
    width: 100%;
    background-color: #dfdfdf;
    height: 1px;
  }
  .progress {
    background-color: #db0032 !important;

    &--one-third,
    &--two-third,
    &--three-third {
      @media screen and (min-width: 375px) {
        & + .bullets {
          padding: 0 1rem 0 3rem;
          justify-content: space-between;
        }
      }
    }
    &--one-third {
      width: 15%;

      @media screen and (min-width: 467px) {
        width: 10%;
      }

      @media screen and (min-width: 960px) {
        width: 5%;
      }
    }
    &--two-third {
      width: 48%;
    }
    &--three-third {
      width: 85%;

      @media screen and (min-width: 467px) {
        width: 90%;
      }

      @media screen and (min-width: 960px) {
        width: 95%;
      }
    }
    &--full {
      width: 100% !important;
    }
    &--first,
    &--second {
      & + .bullets .bullet_container:nth-child(4),
      & + .bullets .bullet_container:nth-child(5) {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }
    }
    &--first {
      width: 15%;

      @media screen and (min-width: 768px) {
        width: 10%;
      }
    }
    &--second,
    &--third,
    &--fourth {
      width: 50%;

      @media screen and (min-width: 768px) {
        width: 27.5%;
      }
    }
    &--third {
      width: 47.5%;

      & + .bullets .bullet_container:nth-child(1),
      & + .bullets .bullet_container:nth-child(5) {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }
      @media screen and (min-width: 768px) {
        width: 46%;
      }
    }
    &--fourth,
    &--fifth {
      width: 46%;

      & + .bullets .bullet_container:nth-child(1),
      & + .bullets .bullet_container:nth-child(2) {
        display: none;

        @media screen and (min-width: 768px) {
          display: block;
        }
      }
      @media screen and (min-width: 768px) {
        width: 66.5%;
      }
    }
    &--fifth {
      width: 81%;

      @media screen and (min-width: 768px) {
        width: 88.5%;
      }
    }
  }
  .bullets {
    position: absolute;
    top: -5px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    .bullet_container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .bullet-label {
        z-index: 50;
        margin-top: -36px;
        color: $omnia-gray;
        overflow: hidden;
        width: 0;
        height: 1.2em;

        @media screen and (min-width: 768px) {
          width: auto;
          height: auto;
        }
      }
      .bullet {
        width: 26px;
        height: 26px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        background-color: #dfdfdf;

        &.active {
          background-color: #db0032;
        }
        .bullet-inner {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fefefe;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
