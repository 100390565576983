// Default styles
@import "default/variables";
@import "default/typography";
@import "default/base";
@import "default/utilities";

// Components
@import "components/form";
@import "components/card";
@import "components/button";
@import "components/accordion";
@import "components/progressbar";
@import "components/lightswitch";
@import "components/orders";
@import "components/product";

// Sections
@import "sections/header";
@import "sections/package";
@import "sections/confirmation";
@import "sections/specifications";

// UI Form Items
@import "components/switch";
@import "../components/intake";

// Hidden Items

.download-button {
    display: none;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    &.content-card {
        max-width: 800px;
    }

}

.row {
    display: flex;

    .col {
        flex-grow: 1;
        padding: 10px;
    }

    .col-1 {
        width: 8%;
    }

    .col-2 {
        width: 16%;
    }

    .col-3 {
        width: 25%;
    }

    .col-4 {
        width: 33%;
    }

    .col-5 {
        width: 41%;
    }

    .col-6 {
        width: 50%;
    }

    .col-7 {
        width: 58%;
    }

    .col-8 {
        width: 66%;
    }

    .col-9 {
        width: 75%;
    }

    .col-10 {
        width: 83%;
    }

    .col-11 {
        width: 91%;
    }

    .col-12 {
        width: 100%;
    }
}

.form-control {
    width: 100%;
    padding: 9px;
}
