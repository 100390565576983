/* Switch */

.switch {
    font-size: 1rem;
    position: relative;
}

.switch input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;
}

.switch input::after {
    content: 'ja';
}

.switch input + label {
    position: relative;
    min-width: calc(calc(2.375rem * .8) * 2);
    border-radius: calc(2.375rem * .8);
    height: calc(2.375rem * .8);
    line-height: calc(2.375rem * .8);
    display: inline-block;
    cursor: pointer;
    outline: none;
    user-select: none;
    vertical-align: middle;
    text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem);
}

.switch input + label::before,
.switch input + label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(calc(2.375rem * .8) * 2);
    bottom: 0;
    display: block;
}

.switch input + label::before {
    right: 0;
    background-color: white;
    border-radius: calc(2.375rem * .8);
    transition: 0.2s all;
}

.switch input + label::after {
    top: 2px;
    left: 2px;
    width: calc(calc(2.375rem * .8) - calc(2px * 2));
    height: calc(calc(2.375rem * .8) - calc(2px * 2));
    border-radius: 50%;
    background-color: $omnia-blue;
    transition: 0.2s all;
}

.switch input:checked + label::before {
    background-color: $omnia-blue;
}

.switch input:checked + label::after {
    margin-left: calc(2.375rem * .8);
    background-color: white;
}

.switch input:focus + label::before {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(236, 236, 236, 0.25);
}

.switch input:disabled + label {
    color: #868e96;
    cursor: not-allowed;
}

.switch input:disabled + label::before {
    background-color: #e9ecef;
}

.switch.switch-sm {
    font-size: 0.875rem;
}

.switch.switch-sm input + label {
    min-width: calc(calc(1.9375rem * .8) * 2);
    height: calc(1.9375rem * .8);
    line-height: calc(1.9375rem * .8);
    text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem);
}

.switch.switch-sm input + label::before {
    width: calc(calc(1.9375rem * .8) * 2);
}

.switch.switch-sm input + label::after {
    width: calc(calc(1.9375rem * .8) - calc(2px * 2));
    height: calc(calc(1.9375rem * .8) - calc(2px * 2));
}

.switch.switch-sm input:checked + label::after {
    margin-left: calc(1.9375rem * .8);
}

.switch.switch-lg {
    font-size: 1.25rem;
}

.switch.switch-lg input + label {
    min-width: calc(calc(3rem * .8) * 2);
    height: calc(3rem * .8);
    line-height: calc(3rem * .8);
    text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem);
}

.switch.switch-lg input + label::before {
    width: calc(calc(3rem * .8) * 2);
}

.switch.switch-lg input + label::after {
    width: calc(calc(3rem * .8) - calc(2px * 2));
    height: calc(calc(3rem * .8) - calc(2px * 2));
}

.switch.switch-lg input:checked + label::after {
    margin-left: calc(3rem * .8);
}

.switch + .switch {
    margin-left: 1rem;
}
