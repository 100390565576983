// color vars;
$omnia-white: #ffffff;
$omnia-blue: #04063E;
$omnia-purple: #413b62;
$omnia-gray: #6d6d6d;
$omnia-light-gray: #F5F5F5;
$omnia-red: #db0032;
$omnia-blue: #084FFF;
$omnia-background: #eef3f9;
$warning-red: #b6173a;
$omnia-accent: #084FFF;

// transitions;
$omnia-transition-fast: .25s ease-out;
$omnia-transition: .5s ease-out;
