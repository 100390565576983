.excalidraw {
    &.excalidraw-container {
        height: 100vh;
        max-height: 680px;
    }

    .App-toolbar {
        .Stack {
            // Desktop
            .ToolIcon {
                &:nth-child(9) {
                    display: none;
                }
            }
        }

        // Hide icons in mobile view
        .App-toolbar-content {
            .ToolIcon {
                margin-right: 3px;

                &[aria-label="Menu"], &[aria-label="Edit"] {
                    display: none;
                }
            }

            justify-content: left;
        }
    }

    // Hide library icon
    .ToolIcon {
        &.ToolIcon__library {
            display: none !important;
        }
    }

}

.intake {
    .files-dropzone {
        background: #fff;
        border: 2px dotted #f12335;
        padding: 10px;
        margin-top: 10px;

        &.invisible-file-input {
            position: relative;

            input {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 1;
            }
        }

        i {
            color: #f12335;
            font-size: 20px;
        }

    }

    .intake-images {
        .order-image-container {
            width: 25%;
            position: relative;
            padding: 5px;

            img {
                width: 100%;
            }

            i {
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                color: #f12335;
                font-size: 20px;
            }
        }
    }


    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            input {
                width: 100%;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                margin-top: 10px;
            }
        }
    }

    .intake-form-card {
        padding: 3.5rem 2rem;

        h3 {
            margin-bottom: 20px;
        }

        .camera-type-container {
            //margin-left: -4.5rem;
            //margin-right: -4.5rem;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            border: 1px solid #ccc;
            margin-bottom: 10px;

            .camera-type-header {
                height: 40px;
            }

            .form__radio-wrapper {
                width: 100%;
            }

        }


    }

}

.intake-details-container {



    .intake-details-info {
        font-size: 14px;

        p {
            font-size: 14px;
        }
    }

    .intake-details-floorplan-container {
        img {
            width: 100%;
        }
    }

    .intake-details-image-container {
        img {
            max-width: 23%;
        }
    }
}

.confirmation__navigation {
    .card {
        margin-top: 2rem;
        padding-top: 5px;
        padding-bottom: 15px;
    }
}

.intake-textarea {
    min-height: 110px;
}

.intake-image-container {
    img {
        width: 100%;
        margin-bottom: 20px;
    }
}

.schematics-container {
    &.fullscreen {
        .schematics-canvas-container {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            padding: 30px;
            z-index: 10000;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .schematics-button-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        .btn {
            padding: 1.5rem;
        }
    }

    .excalidraw .undo-redo-buttons, .excalidraw .eraser-buttons {
        transform: none !important;
        transition: none !important;
    }

    .layer-ui__wrapper__footer-left.zen-mode-transition.layer-ui__wrapper__footer-left--transition-left .Island {
        opacity: 0;
        margin-left: -20px;
    }
}

h3 {
    font-weight: 500;
    display: block;
    font-size: 2rem;
    margin-bottom: 4rem;
    color: #413b62;
}
