.confirmation {
    &__block {
        padding: 3.5rem 3.5rem 3.5rem 0;

        &s {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            border-top: solid 1px #DFDFDF;
            border-bottom: solid 1px #DFDFDF;

            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
        }
        &:not(:last-child) {
            border-bottom: solid 1px #DFDFDF;
        }
        @media screen and (min-width: 768px) {
            width: calc(50% - .5px);

            &:nth-child(3) {
                border-bottom: none;
            }
            &:nth-child(odd) {
                border-right: solid 1px #DFDFDF;
            }
            &:nth-child(even) {
                padding-left: 4.5rem;
            }
        }
    }
    &__description {
        line-height: 2;
        max-width: 40rem;
        font-size: 1.5rem;

        &:not(:last-child) {
            margin-bottom: .25rem;
        }
    }
}
