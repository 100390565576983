*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    color: inherit;
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
    height: 100%;
}
body {
    font-size: 1.6rem;
    font-family: "Ubuntu", sans-serif;
    background-color: $omnia-background;
    height: 100%;
    display: flex;
    flex-direction: column;
}
main {
    &.order_page {
        flex-grow: 1;
        background-color: white;
    }
}
