.form {
    &__group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2.5rem;
        justify-content: space-between;

        .form__input-wrapper:not(:last-child) {
            margin-bottom: 2.5rem;
        }
        &--half {
            @media screen and (min-width: 768px) {
                .form__input-wrapper {
                    width: calc(50% - 2rem);

                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &--third {
            .form__input-wrapper {
                @media screen and (min-width: 768px) {
                    &:first-child {
                        width: calc(50% - 2rem);
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        flex: 1;
                        margin-left: 4rem;
                    }
                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }

                @media screen and (min-width: 960px) {
                    &:first-child {
                        width: 100%;
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        flex: none;
                        margin-left: 0rem;
                    }
                    &:not(:last-child) {
                        margin-bottom: 2.5rem;
                    }
                }

                @media screen and (min-width: 1240px) {
                    &:first-child {
                        width: calc(50% - 2rem);
                    }
                    &:nth-child(2),
                    &:nth-child(3) {
                        flex: 1;
                        margin-left: 4rem;
                    }
                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &__label {
        line-height: 1.6;
        color: $omnia-gray;

        &--colored {
            font-weight: 600;
            color: $omnia-red;
        }
    }
    &__radio,
    &__checkbox {
        margin-right: 1.5rem;

        &-wrapper {
            display: flex;
            position: relative;
            margin-bottom: 2rem;
            align-items: center;

            input {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                opacity: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                position: absolute;
            }
        }
    }
    &__radio {
        height: 2rem;
        min-width: 2rem;
        border-radius: 50%;
        position: relative;
        background-color: #EEF3F9;

        &-wrapper {
            input[type="radio"] {
                &:checked ~ .form__radio::after {
                    top: 50%;
                    left: 50%;
                    content: "";
                    width: 1.2rem;
                    height: 1.2rem;
                    position: absolute;
                    border-radius: 50%;
                    background-color: $omnia-blue;
                    transform: translate(-50%, -50%);
                }
            }
            &:not(:last-child) {
                margin-right: 6rem;
            }
        }
    }
    &__checkbox {
        height: 2rem;
        min-width: 2rem;
        position: relative;
        border-radius: .5rem;
        background-color: #EEF3F9;

        &-wrapper {
            margin-top: 2rem;

            input[type="checkbox"] {
                width: 2rem;
                right: initial;

                &:checked ~ .form__checkbox::after {
                    margin-top: .2rem;
                    top: 50%;
                    left: 50%;
                    content: "";
                    width: 1.2rem;
                    height: 1.2rem;
                    position: absolute;
                    // background-color: $omnia-blue
                    transform: translate(-50%, -50%);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-image: url('/public/img/themes/omnia/icons/checkmark-blue.svg');
                }
            }
            &:nth-child(2) {
                margin-top: .5rem;
            }
            &:nth-child(3) {
                margin-top: .5rem;
            }
        }
    }
    &__input {
        width: 100%;
        border: none;
        outline: none;
        padding: 1rem;
        font-size: 1.6rem;
        font-family: "Ubuntu", sans-serif;
        border-bottom: solid 2px #EED3CA;

        &--custom {
            border: 0;
            width: 100%;
            padding: 1.5rem;
            resize: vertical;
            min-height: 16rem;
            border-radius: 1.5rem;
            background-color: #F5F5F5;
        }
        &-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            &--radio {
                margin-bottom: -2rem;
            }
            &--force-column {
                flex-direction: column;

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
            }
        }
    }
    textarea.form__input {
        resize: vertical;
        min-height: 9rem;
    }
    &__category {
        &:not(:last-child) {
            &::after {
                height: 1px;
                content: "";
                display: block;
                margin: 6rem 3rem;
                background-color: #DFDFDF;
            }
        }
        &:last-child {
            margin-bottom: 10rem;
        }
    }
    &__divider {
        border-top: 0;
        margin: 4rem 0;
        border-width: 2px;
    }
    &__search {
        position: relative;
        margin-bottom: 3rem;

        &.focus {
            border-radius: 1.5rem 1.5rem 0 0;

            .form__search-box {
                display: flex;
            }
        }

        svg {
            top: 50%;
            z-index: 1;
            width: 16px;
            left: 1.5rem;
            height: 16px;
            display: block;
            margin-right: 1rem;
            position: absolute;
            transform: translateY(-55%);
        }
        &-input {
            width: 100%;
            border: none;
            outline: none;
            color: #6D6D6D;
            font-size: 1.5rem;
            border-radius: 1.5rem;
            padding: 1.5rem 1rem 1.5rem 4rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            &::placeholder {
                color: #6D6D6D;
            }
            &:focus {
                border-radius: 1.5rem 1.5rem 0 0;

                .form__search-box {
                    display: flex;
                }
            }
        }
        &-box {
            left: 0;
            right: 0;
            top: 100%;
            z-index: 1;
            display: none;
            overflow: auto;
            padding: 1.5rem;
            max-height: 23rem;
            position: absolute;
            flex-direction: column;
            background-color: $omnia-white;
            border-radius: 0 0 1.5rem 1.5rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        &-item {
            font-size: 1.5rem;
            padding: .75rem 2rem;
            color: $omnia-gray;
            white-space: nowrap;

            &:not(:last-child) {
                &::after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    display: block;
                    margin-top: 1.5rem;
                    background-color: #DFDFDF;
                }
            }
        }
    }
    &__date-picker {
        width: 100%;
        position: relative;

        &-icon {
            z-index: 1;
            top: 1.25rem;
            left: 1.5rem;
            position: absolute;
            pointer-events: none;
        }
    }

    &__input {
        &.invalid {
            border: 1px solid $warning-red;
        }
    }
}

//.specifications__interaction {
//    .form__input {
//        width: 7rem;
//        border: none;
//        outline: none;
//        font-size: 1.6rem;
//        color: $omnia-gray;
//        border-radius: .25rem;
//        padding: .5rem 1rem .5rem 1.5rem;
//        background-color: $omnia-white;
//    }
//}

.invalid-feedback {
    display: block;
    font-size: 14px;
    color: $warning-red;
}

.signature-pad {
    background-color: #f5f5f5;
    border-radius: 1.5rem;
    width: 100%;
    margin-bottom: 20px;
}

.phone-field-container {
    margin-top: 10px;
    margin-bottom: 10px;

    .number-btn-container {
        position: absolute;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 4px;
        top: 0;

        button {
            width: 100%;
            height: 100%;
            border: 0;
            background-color: transparent;
            font-weight: bold;
            font-size: 15px;
        }
    }
}

.mobile-number-field-container {
    .form__input-wrapper {
        &:nth-child(2) {
            margin: 10px;
        }
    }
}

.form__input-wrapper {
    &.number-wrapper {
        position: relative;

        .add-number-btn {
            position: absolute;
            right: 5px;
            height: 100%;
            width: 25px;
            color: $omnia-blue;
        }

        .form__input {
            width: 80px;
            -moz-appearance: textfield !important;
            border-bottom: none;
            border-radius: .5rem;

            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none !important;
                margin: 0;
            }
        }
    }
}

.form__input-wrapper {
    &.symfony {
        .is-invalid {
            border: 1px solid $warning-red;
        }
    }
}

