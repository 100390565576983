.header {
    margin-top: 5rem;

    .container {
        display: flex;
        flex-direction: column-reverse;

        @media screen and (min-width: 768px) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__info {
        margin-top: 7rem;

        @media screen and (min-width: 365px) {
            padding: 0 2rem;
        }

        @media screen and (min-width: 768px) {
            padding: 0;
            max-width: 30rem;
            margin-bottom: 7.5rem;
        }

        @media screen and (min-width: 960px) {
            max-width: 42rem;
        }
    }
    &__dots {
        height: 1.8rem;
    }
    &__sub-title {
        font-weight: 900;
        color: $omnia-red;
        font-size: 1.4rem;
        text-transform: uppercase;

        &-wrapper {
            align-items: center;
            margin-bottom: 1rem;

            @media screen and (min-width: 768px) {
                margin-bottom: 1.5rem;
            }
        }
        @media screen and (min-width: 768px) {
            font-size: 1.6rem;
        }
    }
    &__title {
        &--invert {
            color: $omnia-red;
            font-size: inherit;
        }
    }
    &__description {
        color: #6D6D6D;
    }
    &__image {
        flex: 1;
        width: 100%;
        margin: 0 auto;
        max-width: 42rem;
        position: relative;

        img {
            width: 100%;
            display: block;
        }
        @media screen and (min-width: 768px) {
            max-width: 55%;
            margin-right: -5.5rem;
        }

        .price {
            position: absolute;
            bottom: 0;
            background-color: white;
            border-radius: 50%;
            width: 20rem;
            height: 20rem;
            right: 230px;
        }
    }
    &__centerer {
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        margin-bottom: 10rem;
        flex-direction: column;

        .header__title {
            @media screen and (min-width: 960px) {
                &-break {
                    display: none;
                }
            }
        }
        .header__description {
            max-width: 26rem;

            @media screen and (min-width: 960px) {
                max-width: none;
            }
        }
        @media screen and (min-width: 960px) {
            margin-bottom: 14rem;
        }
    }
}
