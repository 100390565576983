.card {
    width: 100%;
    padding: 3.5rem;
    border-radius: 30px;
    background-color: $omnia-white;

    &--softcorners {
        border-radius: 15px;
    }
    &__title {
        font-size: 2.8rem;
        font-weight: 600;
        margin-bottom: 3rem;
        color: $omnia-purple;

        @media screen and (min-width: 768px) {
            font-size: 3rem;
        }
    }
    @media screen and (min-width: 768px) {
        padding: 3.5rem 4.5rem;
    }
}

    // padding-top: 50px
    // padding-right: 50px
    // padding-bottom: 50px
    // padding-left: 50px
    // background-color: #FFFFFF
    // border-radius: 30px 30px 30px 30px
    // margin-bottom: 50px

    // &__title
    //     position: relative
    //     margin-top: 0px
    //     margin-bottom: 40px
    //     margin-left: 12px
    //     margin-right: 12px

    //     @media only screen and (max-width: 767px)
    //         margin: 0 0 20px
    //         height: auto!important

    // &__content
    //     margin-top: 0px
    //     margin-bottom: 40px
    //     margin-left: 12px
    //     margin-right: 12px

    //     @media only screen and (max-width: 767px)
    //         margin: 0 0 20px
    //         height: auto!important
