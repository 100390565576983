.lightswitch {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > * {
        pointer-events: none;
    }
    &--active {
        .lightswitch__wrapper {
            background-color: $omnia-blue;
        }
        .lightswitch__ball {
            left: calc(100% - 2rem);
            background-color: $omnia-white;
        }
    }
    &__wrapper {
        height: 2rem;
        width: 4.5rem;
        position: relative;
        border-radius: 1.5rem;
        background-color: $omnia-white;
        transition: background-color $omnia-transition;
    }
    &__ball {
        top: 0;
        left: 0;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        position: absolute;
        background-color: $omnia-blue;
        transition: background-color $omnia-transition, left $omnia-transition;
    }
    &__label {
        margin-top: .75rem;
        color: $omnia-gray;
        white-space: nowrap;

        &--small {
            font-size: 1.2rem;
        }
    }
    @media screen and (min-width: 768px) {
        align-items: flex-end;
    }
}
